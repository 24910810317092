var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c("div", { attrs: { align: "center" } }, [
        _c("br"),
        _vm._m(0),
        _c("div", { staticClass: "row justify-center  q-px-md" }, [
          _c(
            "div",
            {
              staticClass: "col-12 col-md-5 q-px-sm",
              attrs: { align: "left" },
            },
            [
              _c("fieldset", [
                _c("legend", [_vm._v("Dati della pratica")]),
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Numero interno: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.pratica.numero)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Numero: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(_vm._s(_vm.pratica.numero_pratica)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "domanda" }, [
                        _vm._v("Cliente: "),
                      ]),
                      _c("td", { staticClass: "risposta" }, [
                        _vm._v(
                          _vm._s(_vm.cliente.cognome) +
                            " " +
                            _vm._s(_vm.cliente.nome) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("br"),
              ]),
              _c("br"),
              _c("div", { staticClass: "text-h5", attrs: { align: "left" } }, [
                _vm._v("Dati economici"),
              ]),
              _c("br"),
              _vm._l(_vm.get_dati_economici, function (dato_economico, index) {
                return _c(
                  "div",
                  _vm._b({ key: index }, "div", dato_economico, false),
                  [
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        type: "number",
                        dense: "",
                        outlined: "",
                        label: dato_economico.tipo,
                        step: "1",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function () {
                              return [_vm._v(" Euro ")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: dato_economico.importo,
                        callback: function ($$v) {
                          _vm.$set(dato_economico, "importo", $$v)
                        },
                        expression: "dato_economico.importo",
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-5 q-px-sm",
              attrs: { align: "left" },
            },
            [
              _c("div", { staticClass: "text-h5", attrs: { align: "left" } }, [
                _vm._v("Dati del Contratto"),
              ]),
              _c("br"),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  readonly: "",
                  label: "Tipo di contratto",
                },
                model: {
                  value: _vm.dati_contratto_modificato.prodotto_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_contratto_modificato, "prodotto_id", $$v)
                  },
                  expression: "dati_contratto_modificato.prodotto_id",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  dense: "",
                  outlined: "",
                  readonly: "",
                  label: "Impresa di assicurazione",
                },
                model: {
                  value: _vm.dati_contratto_modificato.compagnia,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_contratto_modificato, "compagnia", $$v)
                  },
                  expression: "dati_contratto_modificato.compagnia",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  outlined: "",
                  dense: "",
                  "error-message":
                    "Il Numero di polizza della Compagnia deve essere inserito",
                  label: "Numero di Polizza Compagnia",
                },
                model: {
                  value: _vm.dati_contratto_modificato.numero_polizza_compagnia,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dati_contratto_modificato,
                      "numero_polizza_compagnia",
                      $$v
                    )
                  },
                  expression:
                    "dati_contratto_modificato.numero_polizza_compagnia",
                },
              }),
              !_vm.tariffa_esistente
                ? [
                    _c("q-select", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        outlined: "",
                        dense: "",
                        "error-message": "Il prodotto deve essere valorizzato",
                        options: _vm.elenco_prodotti,
                        label: "Prodotto a catalogo",
                        name: "prodotto_catalogo",
                        "virtual-scroll-slice-size": "0",
                        "virtual-scroll-item-size": "0",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.onSelectProdotto()
                        },
                      },
                      model: {
                        value: _vm.prodotto_da_catalogo,
                        callback: function ($$v) {
                          _vm.prodotto_da_catalogo = $$v
                        },
                        expression: "prodotto_da_catalogo",
                      },
                    }),
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        outlined: "",
                        dense: "",
                        label: "Fornitore",
                        readonly: "",
                      },
                      model: {
                        value: _vm.dati_contratto_modificato.fornitore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dati_contratto_modificato,
                            "fornitore",
                            $$v
                          )
                        },
                        expression: "dati_contratto_modificato.fornitore",
                      },
                    }),
                    _c("q-input", {
                      staticClass: "q-mb-sm",
                      attrs: {
                        outlined: "",
                        label: "Tariffa",
                        readonly: "",
                        dense: "",
                      },
                      model: {
                        value: _vm.dati_contratto_modificato.tariffa,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dati_contratto_modificato,
                            "tariffa",
                            $$v
                          )
                        },
                        expression: "dati_contratto_modificato.tariffa",
                      },
                    }),
                  ]
                : _vm._e(),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  outlined: "",
                  mask: "##/##/####",
                  dense: "",
                  label: "Data di decorrenza",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "q-icon",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { name: "event" },
                            },
                            [
                              _c(
                                "q-popup-proxy",
                                {
                                  ref: "qDateProxy",
                                  attrs: {
                                    "transition-show": "scale",
                                    "transition-hide": "scale",
                                  },
                                },
                                [
                                  _c(
                                    "q-date",
                                    {
                                      attrs: { mask: "DD/MM/YYYY" },
                                      model: {
                                        value: _vm.decorrenza_contratto,
                                        callback: function ($$v) {
                                          _vm.decorrenza_contratto = $$v
                                        },
                                        expression: "decorrenza_contratto",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row items-center justify-end",
                                        },
                                        [
                                          _c("q-btn", {
                                            directives: [
                                              {
                                                name: "close-popup",
                                                rawName: "v-close-popup",
                                              },
                                            ],
                                            attrs: {
                                              label: "Close",
                                              color: "primary",
                                              flat: "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3262126127
                ),
                model: {
                  value: _vm.decorrenza_contratto,
                  callback: function ($$v) {
                    _vm.decorrenza_contratto = $$v
                  },
                  expression: "decorrenza_contratto",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  outlined: "",
                  mask: "##/##/####",
                  dense: "",
                  label: "Data di scadenza contrattuale",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "q-icon",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { name: "event" },
                            },
                            [
                              _c(
                                "q-popup-proxy",
                                {
                                  ref: "qDateProxy",
                                  attrs: {
                                    "transition-show": "scale",
                                    "transition-hide": "scale",
                                  },
                                },
                                [
                                  _c(
                                    "q-date",
                                    {
                                      attrs: { mask: "DD/MM/YYYY" },
                                      model: {
                                        value: _vm.scadenza_contratto,
                                        callback: function ($$v) {
                                          _vm.scadenza_contratto = $$v
                                        },
                                        expression: "scadenza_contratto",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row items-center justify-end",
                                        },
                                        [
                                          _c("q-btn", {
                                            directives: [
                                              {
                                                name: "close-popup",
                                                rawName: "v-close-popup",
                                              },
                                            ],
                                            attrs: {
                                              label: "Close",
                                              color: "primary",
                                              flat: "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3073692439
                ),
                model: {
                  value: _vm.scadenza_contratto,
                  callback: function ($$v) {
                    _vm.scadenza_contratto = $$v
                  },
                  expression: "scadenza_contratto",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  outlined: "",
                  mask: "##/##/####",
                  dense: "",
                  label: "Data di decorrenza di rata",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "q-icon",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { name: "event" },
                            },
                            [
                              _c(
                                "q-popup-proxy",
                                {
                                  ref: "qDateProxy",
                                  attrs: {
                                    "transition-show": "scale",
                                    "transition-hide": "scale",
                                  },
                                },
                                [
                                  _c(
                                    "q-date",
                                    {
                                      attrs: { mask: "DD/MM/YYYY" },
                                      model: {
                                        value: _vm.decorrenza_quietanza,
                                        callback: function ($$v) {
                                          _vm.decorrenza_quietanza = $$v
                                        },
                                        expression: "decorrenza_quietanza",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row items-center justify-end",
                                        },
                                        [
                                          _c("q-btn", {
                                            directives: [
                                              {
                                                name: "close-popup",
                                                rawName: "v-close-popup",
                                              },
                                            ],
                                            attrs: {
                                              label: "Close",
                                              color: "primary",
                                              flat: "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1715705997
                ),
                model: {
                  value: _vm.decorrenza_quietanza,
                  callback: function ($$v) {
                    _vm.decorrenza_quietanza = $$v
                  },
                  expression: "decorrenza_quietanza",
                },
              }),
              _c("q-input", {
                staticClass: "q-mb-sm",
                attrs: {
                  outlined: "",
                  mask: "##/##/####",
                  dense: "",
                  label: "Data di scadenza di rata",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "q-icon",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { name: "event" },
                            },
                            [
                              _c(
                                "q-popup-proxy",
                                {
                                  ref: "qDateProxy",
                                  attrs: {
                                    "transition-show": "scale",
                                    "transition-hide": "scale",
                                  },
                                },
                                [
                                  _c(
                                    "q-date",
                                    {
                                      attrs: { mask: "dd/MM/yyyy" },
                                      model: {
                                        value: _vm.scadenza_quietanza,
                                        callback: function ($$v) {
                                          _vm.scadenza_quietanza = $$v
                                        },
                                        expression: "scadenza_quietanza",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row items-center justify-end",
                                        },
                                        [
                                          _c("q-btn", {
                                            directives: [
                                              {
                                                name: "close-popup",
                                                rawName: "v-close-popup",
                                              },
                                            ],
                                            attrs: {
                                              label: "Close",
                                              color: "primary",
                                              flat: "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1900686709
                ),
                model: {
                  value: _vm.scadenza_quietanza,
                  callback: function ($$v) {
                    _vm.scadenza_quietanza = $$v
                  },
                  expression: "scadenza_quietanza",
                },
              }),
              _c("br"),
            ],
            2
          ),
        ]),
        _c("div", { attrs: { align: "center" } }, [
          _c("hr"),
          _c("div", { staticClass: "row justify-center" }, [
            _c(
              "div",
              { staticClass: "col-md-4", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "ANNULLA MODIFICHE",
                    color: "blue-grey",
                    icon: "undo",
                    size: "md",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIndietroClicked()
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "REGISTRA",
                    color: "blue-grey",
                    icon: "mdi-arrow-right-bold",
                    size: "md",
                    icon_align: "right",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onRegistraClicked()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Modifica dati contratto"),
      ]),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }